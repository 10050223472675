import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import "./Video.scss"

class Video extends Component {
    render () {
        return (      
        <div className='player-wrapper'>
            <div className='player-wrapper2' /*style={{width:width, height:height}}*/>
                <div className='player-close-button-x' onClick={this.props.closeVideo}>X</div>
                <ReactPlayer
                className='react-player fixed-bottom'
                url= '../videos/Align Technology Video 2017+.mp4'
                width="100%"
                height="auto"
                controls = {true}
                />
            </div>
        </div>
        )
    }
}

export default Video;