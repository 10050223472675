import React, { useState } from 'react'

import HeaderPage2 from '../components/HeaderPage2/HeaderPage2'
import Footer from '../components/Footer/Footer'

import VideoInvisalign from '../components/Video/Video'
import VideoInvisalignGatsby from '../components/Video/VideoGatsby'
import SaltybridgeLoader from '../components/sb/SaltybridgeLoader'
import SaltybridgeTopMenu from '../components/sb/SaltybridgeTopMenu'
import SaltybridegSection11 from '../components/sb/SaltybridegSection11'
import SaltybridegSection23 from '../components/sb/SaltybridegSection23'
import SaltybridegSection21 from '../components/sb/SaltybridegSection21'
import SaltybridegSection4ortho from '../components/sb/SaltybridegSection4ortho'
import SaltybridegSection6ortho from '../components/sb/SaltybridegSection6ortho'

import GoToTopButton from '../components/GoToTop'
import { withTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby';
import { SEO } from "../components/SEO/SEO"

import img_0 from '../images/ortho/img_bg_2salogom.jpg'
import img_1 from '../images/ortho/CA_INVI_D4_A_Hygenist_0130_rt1_HR_RGB.jpg'
import img_2 from '../images/ortho/INV_ClickIn_3364_rt4_HR_RGB.jpg'
import img_3 from '../images/ortho/ca-invi-d2-a-intproduct-0112-rt1-hr.jpg'

const Invisalign = (props) => {
  const [isShowingVideo, setShowingVideo] = useState(false)
  const topMenuRef = React.useRef()

  const { location } = props;
  const putanja = location.pathname;

    const width = 800
    const height = 500
    const t = props.t
    const s13_props = { text1: t('sbcom_ss13_ortho_inv_text1'),
        text2: t('sbcom_ss13_ortho_inv_text2'),
        text3: t('sbcom_ss13_ortho_inv_text3'),
        text4: t('sbcom_ss13_ortho_inv_text4'),
        /*text5: t('sbcom_ss13_ortho_inv_text5'),*/
        text5: t('sbcom_ss13_ortho_inv_text6'),
        text6: t('sbcom_ss13_ortho_inv_text7'),
        text7: t('sbcom_ss13_ortho_inv_text8'),
        text8: t('sbcom_ss13_ortho_inv_text9') }

    function scrollTo() {
        if (!topMenuRef.current) return;
        topMenuRef.current.scrollIntoView({ behavior: "smooth", block:"center"});
      }

    return (
        <div className="Main">
            <SaltybridgeLoader />
            
            <div id="page">
                {isShowingVideo && <VideoInvisalign  width={width} height={height} closeVideo={(e) => { setShowingVideo(false); }}/>}
            
                <SaltybridgeTopMenu pathname={putanja} onContactUsClick={scrollTo}/>
                <HeaderPage2 name={t("sbcom_header_page_invisalign")} image={img_0} videoCallbackFunction={(e) => { setShowingVideo(true); }} />
                <SaltybridegSection23 image1={img_1} image2={img_2} content={s13_props} />
                <SaltybridegSection21 />
                <SaltybridegSection4ortho/>
                <SaltybridegSection6ortho />  
                <SaltybridegSection11 ref={topMenuRef}/>
                <Footer />
            </div>
            <GoToTopButton />
        </div>
    );
}

export default withTranslation()(Invisalign);

export const Head = () => (
  <SEO />
)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["translation"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;