import React from 'react';
import "./SaltybridegSection6ortho.scss"
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function SaltybridegSection6ortho(){
    const {t} = useTranslation()

    return  <div id="sbcom-started">
        <div className="container">
            <div className="row animate-box">
                <div className="col-md-8 col-md-offset-2 text-center sbcom-heading">
                    <span style={{fontSize: "1rem"}}>{t('sbcom_ss6_ortho_inv_text1')}</span>
                    <h2>{t('sbcom_ss6_ortho_inv_text2')} </h2>
                    <p style={{fontSize: "1.25rem"}}>{t('sbcom_ss6_ortho_inv_text3')}</p>
                    <p><button type="submit" className="btn btn-primary"><a target="_blank" rel="noopener noreferrer" href={t('3pweb.invisalign_smile_link')}>{t('sbcom_ss6_ortho_inv_text4')}</a></button></p>
                </div>
            </div>
        </div>
    </div>
}