import React from 'react';
import "./SaltybridgeSection23.scss"
import "../../../scss/components/saltybridge/heading.scss"

import {Img} from 'react-image'

export default function SaltybridegSection23(props){
  const {image1, image2, content} = props

    return (
    <div id="sbcom-orth12">
      <div className="container lg">

        <div className="sbcom-left-div-holder">
          <div className="holder-left">
            <div className="sbcom-image-holder1 sbcom-offset-dots-white">
              <Img className="img1" src={image1}></Img>
            </div>
            <div className="sbcom-image-holder2">
              <Img className="img2" src={image2}></Img>
            </div>
          </div>
        </div>

        <div className="sbcom-right-dev-holder">
          <div className="holder-right">
              <div className="text1">
                <h1>{content.text1}<strong>{content.text2}</strong>{content.text3}<strong>{content.text4}</strong>
                </h1>
              </div>
              <div className="text2">
                <p className="description1">
                {content.text5}
                </p>
              </div>
              {/*<div className="text3">
                <p className="description2">
                {content.text0}
                </p>
            </div>*/}
            <div className="text3" >
                <p className="description3" style={{fontSize:"1.1rem", marginTop: "5px", padding: "5px", width: "fit-content"}}>
                <i style={{color: "#09639b"}} className="icon-play"/>{content.text6}
                </p>
                <p className="description3" style={{fontSize:"1.1rem", marginTop: "5px", padding: "5px",width: "fit-content"}}>
                <i style={{color: "#09639b"}}  className="icon-play"/>{content.text7}
                </p>
                <p className="description3" style={{fontSize:"1.1rem", marginTop: "5px", padding: "5px", width: "fit-content"}}>
                <i style={{color: "#09639b"}}  className="icon-play"/>{content.text8}
                </p>
            </div>
          </div>
        </div>
        
      </div>
    </div>)
}