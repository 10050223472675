import React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next'
import './HeaderPage2.scss';

const HeaderPage2 = (props) =>{ 
  const {t} = useTranslation()
  const image = props.image
  const videoCallbackFunction = props.videoCallbackFunction

  return (
  <header id="sbcom-header" className="sbcom-cover" role="banner" 
  style={{backgroundImage:`url(${image})`}}
  data-stellar-background-ratio="0.5">
    <div className="container">
      <div className="row">
        <div className="col-md-7 text-left">
          <div className="display-t">
            <div className="display-tc animate-box" data-animate-effect="fadeInUp">
              <h3 className="mb30">{t('sbcom_header2_orth_text0')}</h3>
              <h1 className="mb30">{t('sbcom_header2_orth_text1')}</h1>
              <p>
                <a href={t('3pweb.link')} target="_blank" className="btn btn-primary">{t('sbcom_header2_orth_text2')}</a>
                <em className="or" style={{fontSize:"26px", color:"#fff"}}>{t('sbcom_header_orth_text3')}</em>
                <a onClick={(e) => videoCallbackFunction(e)} style={{fontSize:"26px", color:"#fff"}} className="link-watch popup-vimeo">{t('sbcom_header2_orth_text4')} <i className="icon-video2"/></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
)};

HeaderPage2.propTypes = {};

HeaderPage2.defaultProps = {};

export default HeaderPage2;
