import React, { Component } from 'react'
import DogVideo from "./videos/AlignTechnologyVideo2017+.mp4"
import "./Video.scss"

class Video extends Component {
    render() {
        return (
            <div className='player-wrapper'>
                <div className='player-wrapper2' /*style={{width:width, height:height}}*/>
                    <div className='player-close-button-x' onClick={this.props.closeVideo}>X</div>
                    <video controls>
                        <source src={DogVideo} type="video/mp4" />
                </video>
                </div>
            </div>
        )
    }
}

export default Video;