import React from 'react';
import "../../../scss/components/saltybridge/heading.scss"
import "../../../scss/components/saltybridge/feature_center.scss"
import "./SaltybridegSection4ortho.scss"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {Img} from 'react-image'

import img_desk from '../../../images/ortho/03 - CA_INVI_D2_A_Traveling_0075_rt1_HR_RGB.jpg';
import img_d from '../../../images/ortho/ca-invi-d2-a-intproduct-0112-rt1-hr-s.jpg'

export default function SaltybridegSection4ortho(){
    const {t} = useTranslation()

    return  <div id="sbcom-inv-services" className="sbcom-bg-section border-bottom">
    <div className="container">
    <div className="row row-pb-md">
        <div className="col-md-8 col-md-offset-2 text-left animate-box" data-animate-effect="fadeInUp">
        <div className="sbcom-heading">
            <span style={{fontSize: "1rem"}}>{t('sbcom_ss2_ortho_inv_text1a')}<br/>{t('sbcom_ss2_ortho_inv_text1b')}</span>
            <h2>{t('sbcom_ss2_ortho_inv_text2')}</h2>
            <p style={{fontSize: "1.25rem", paddingTop: "10px"}}>{t('sbcom_ss2_ortho_inv_text3')}</p>
            <p style={{fontSize: "1.25rem"}}>{t('sbcom_ss2_ortho_inv_text4')}</p>
        </div>
        </div>
    </div>
    <div className="row">
        <div className="col-md-12 col-md-offset-3">
            <div className="col-md-6">
                <div className="menjaj-div-slike">
                    <Img className="menjaj-sliku" src={img_d}></Img>
                </div>
            </div>
            <div className="clearfix visible-md-block"></div>
        </div>
    </div>
    {/* old code for the one with horizontal image ('lik koji dominira' image XD)
    <div className="row">
        <div className="col-md-12 ">
            <div className="menjaj-div-slike">
                <Img className="menjaj-sliku" src={img_desk}></Img>
            </div>
        </div>
        <div className="clearfix visible-md-block"></div>
    </div>*/}
    </div>
</div>
}